@import "src/styles/mixins";
@import "src/styles/props";

.baseContainer {
  width: 100%;

  .announcements {
    @include for-phone-only{
      margin-top: 20px;
    }
  }
}

.oemApprovalPageContainer {
  @include item-flex(1, 1, 0);
  @include padding(27px 10% 56px 10%);
  @include for-phone-only{
    @include padding(20px 15px 20px 15px);
    width: 100%;
  }

  &.noOtherVersions {
    @include padding(27px 15% 56px 15%);

    @include for-phone-only{
      @include padding(20px 15px 20px 15px);
      width: 100%;
    }

    .mainContent {
      .leftContent {
        width: 100%;

        .titlePart {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          @include for-phone-only{
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  .mainContent {
    display: flex;
    flex-direction: row;

    @include for-phone-only{
      flex-direction: column;
    }

    .leftContent {
      width: 70%;

      @include for-phone-only{
        width: 100%;
      }
    }

    .otherVersionContent {
      width: 30%;
      padding-left: 20px;
      display: flex;
      flex-direction: column;

      @include for-phone-only{
        width: 100%;
        padding-left: 0;
      }

      .otherVersionsWrapper {
        overflow: auto;
        width: 100%;
        max-height: 1000px;

        @include for-phone-only {
          max-height: unset;
        }
      }
    }
  }

}

.approvalName {
  @include CastrolSansConNormal;
  font-size: 36px;
  line-height: 48px;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 16px;

  @include for-phone-only {
    margin-left: 0;
  }
}

.title {
  @include CastrolSansConNormal;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 16px;

  &.otherVersion {
    margin-left: 0;
    margin-bottom: 12px;
  }

  @include for-phone-only {
    margin-left: 0;
  }
}

.dateAndDownloadRow {
  @include flex(center, space-between, row);
  background-color: rgba($light-grey, 0.1);
  padding: 12px 16px;
  margin-top: 24px;

  &.otherVersion {
    margin-top: 10px;
  }

  @include for-phone-only {
    @include border-bottom(1px solid $gainsboro);
    padding: 12px 0;
    margin-top: 20px;

    &.otherVersion {
      border-bottom: none;
      background-color: unset;
      margin-top: 0;
    }

  }

  .itemField {
    @include flex(center, flex-start, row);

    .fieldContent {
      @include flex(flex-start, flex-start, column);
      margin-left: 16px;

      .fieldLabel {
        @include CastrolSansConNormal;
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        text-transform: uppercase;
        color: $dim-gray;
      }

      .fieldValue {
        @include CastrolSansConNormal;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
      }
    }
  }

  .download {
    @include flex(center);
    @include CastrolSansConNormal;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: $pigment-green;
    cursor: pointer;
    text-transform: uppercase;

    svg {
      margin-right: 16px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.descriptionRow {
  @include flex(center, space-between, row);
  @include Arial;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: $dim-gray;
  padding: 30px 16px;

  .shareEmail {
    @include flex(center);
    @include CastrolSansConNormal;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: $pigment-green;
    cursor: pointer;
    text-transform: uppercase;

    @include for-phone-only {
      margin-top: 12px;
    }

    svg {
      margin-right: 16px
    }
  }

  @include for-phone-only {
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
    margin: 20px 0;
  }
}

.divider {
  @include rect(100%, 1px);
  background-color: $gainsboro;
  margin: 20px 0;
  display: none;

  @include for-phone-only {
    display: block;
  }
}

.relatesContainer {
  @include flex(flex-start, stretch, column, nowrap);
  padding: 20px;
  background-color: rgba($light-grey, 0.1);
  border: 1px solid $gainsboro;
  margin-bottom: 24px;

  @include for-phone-only {
    padding: 14px 20px;
    max-height: unset;
  }

  .productListWrapper {
    overflow: auto;
    width: 100%;
    max-height: 600px;

    @include for-phone-only {
      max-height: unset;
    }
  }

  .relatesLabel {
    @include CastrolSansConNormal;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: $dim-gray;
    text-transform: uppercase;
    margin-bottom: 4px;
    padding-left: 20px;
    position: relative;
    width: 100%;

    @include for-phone-only {
      padding-bottom: 14px;
      padding-left: 0;
    }

    .firstRowDivider {
      @include rect(calc(100% + 40px), 1px);
      background-color: $gainsboro;
      position: absolute;
      bottom: 0px;
      left: -20px;
      display: none;

      @include for-phone-only {
        display: block;
      }
    }
  }


  .relatesField {
    @include CastrolSansConNormal;
    background-color: $white;
    width: calc(100% - 40px);
    padding: 14px 20px;
    margin-top: 5px;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .link {
      @include CastrolSansConNormal;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: $pigment-green;
      flex-grow: 1;
      @include for-phone-only {
        width: 80% !important;
      }
    }
  
    .editButton, .deleteButton {
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      border: none;
      color: $pigment-green;
      margin-left: 10px;
      transition: background-color 0.3s;
    }
  }
}


.actionRow {
  display: flex;
  flex-direction: row;
  width: 100%;

  @include for-phone-only {
    display: none;
  }

  .backButton {
    width: 110px;
  }
}

.uploadButton {
  width: 120px;

  &.desktop {
    align-self: flex-end;
  }

  &.mobile {
    display: none;

    &.noOtherVersions {
      display: block;
    }
  }

  @include for-phone-only {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
      margin-top: 20px;
    }
  }
}

// loading
.loading {
  height: 100px;
}

