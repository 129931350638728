@import "src/styles/mixins";
@import "src/styles/props";

.modalContainer{
  max-width: 865px;
  height: 100%;
  max-height: 580px;
  overflow: auto;

  @include for-phone-only{
    max-width: unset;
  }
}

// container
.container {
  @include padding(48px);

  @include for-phone-only {
    padding: 32px 20px;
  }

  .title {
    @include CastrolSansConRegular;
    font-size: 36px;
    line-height: 48px;
    text-transform: uppercase;

    @include for-phone-only {
      font-size: 26px;
      line-height: 35px;
    }
  }

  .description {
    @include Arial;
    font-size: 16px;
    line-height: 22px;
    color: $dim-gray;
    margin-top: 20px;
  }

  .subTitle {
    @include CastrolSansConRegular;
    font-size: 16px;
    line-height: 22px;
    margin-top: 24px;
  }

  .datePicker {
    @include border(1px solid $gainsboro);
    width: 100%;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .fileInput {
    padding-left: 20px;
    padding-right: 20px;

    .fileInputText {
      @include Arial;
      font-size: 14px;
    }
  }

  .pinIcon {
    position: absolute;
    right: 17.6px;
    top: 50%;
    margin-top: calc(-11px / 2);
    cursor: pointer;
  }
}

.inputWrapper {
  border: 1px solid $gainsboro;
  padding: 0;

  input {
    @include Arial;
    width: calc(100% - 22px);
    padding: 0px 10px;
    border: 0;
    height: 45px;
    font-size: 14px;
    line-height: 15px;
  }
}

.checkboxWrapper {
  @include flex(center, flex-start, row, nowrap);
  padding: 12px 14px;
  border: 1px solid $gainsboro;

  .checkboxLabel {
    @include Arial;
    font-size: 14px;
    line-height: 16px;
    margin-left: 12px;
    cursor: pointer;
  }
}

.selectControl {
  border: none;
  height: 40px;

  &.nameSelector {
    width: 218px
  }

  svg {
    right: 10px;
  }
}

.selectInput {
  @include Arial;
  font-size: 14px;
  height: 100px;
  padding: 12px 10px;
  border: 1px solid $gainsboro;
}

.selectOption {
  @include Arial;
  font-size: 14px;
}

.actionBtnContainer {
  @include flex(null, flex-start);
  @include border-top(1px solid $gainsboro);
  margin-top: 32px;
  padding-top: 32px;

  @include for-phone-only {
    @include flex(null, space-between);
    margin-top: 20px;
    padding-top: 20px;
  }

  .uploadButton {
    width: 116px;
  }

  .cancelButton {
    width: 96px;
    margin-left: 24px;
  }

}


// close icon
.close {
  @include box(20px);
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 21;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
}
